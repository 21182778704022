// import React from 'react';
// import { ThemeProvider } from 'styled-components';

// const theme = {
//   colors: {
//     white: '#fff',
//     powderWhite: '#FFFDF9',
//     lightGrey: '#d6d6d6',
//     idiotBlue: '#3a6bb2',
//     haveLockBlue: '#647EB1',
//     persimon: '#EC6608',
//     onyx: '#36313D',
//     nightRider: '#333',
//     pitchBlack: '#000',
//   },
// };

// eslint-disable-next-line react/prop-types
// const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

// export default theme;

export default {
  colors: {
    white: '#fff',
    powderWhite: '#FFFDF9',
    lightGrey: '#d6d6d6',
    idiotBlue: '#3a6bb2',
    haveLockBlue: '#647EB1',
    persimon: '#EC6608',
    onyx: '#36313D',
    nightRider: '#333',
    pitchBlack: '#000',
  },
  fonts: {
    fjalla: `Fjalla One, sans-serif`,
    noto: `Noto Sans, sans-serif`
  }
}