import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import Theme from './src/theme/theme'
import { device } from './src/theme/BreakPoints'

const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  font-family: ${props => props.theme.fonts.noto};
  /* font-family: 'Noto Sans', sans-serif; */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.65;
  color: #333;
  height: 100%;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  font-family: ${props => props.theme.fonts.fjalla};
  font-weight: 400;
  line-height: 1.15;
  margin: 1.75rem 0 1.05rem;
  cursor: default;
}

h1 {
  margin-top: 0;
  font-size: 4.375em;
  /* font-size: 3.052em; */
}

h2 {font-size: 2.441em;}

h3 {font-size: 1.953em;}

h4 {font-size: 1.563em;}

h5 {font-size: 1.25em;}

small, .text_small {font-size: 0.8em;}

p {
  margin-bottom: 1.15rem;
}

a {
  font-family: ${props => props.theme.fonts.noto};
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;
}

.btn {
  padding: 0.75rem 2rem;
  min-width: 13.75rem;
  border-radius: 0.375rem;
  text-align: center;
  background-color: #ab2633;
  color: white;
  font-family: ${props => props.theme.fonts.noto};
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;
  transition: background-color 0.3s;

  &:hover,
  &:active {
    background-color: #212121;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  border: 0;
  clip: rect(0,0,0,0);
  height: .0625rem;
  margin: -.0625rem;
  padding: 0;
  width: .0625rem;
}

.container {
  margin: 0 auto;
  max-width: 100%;

  @media ${device.mobileS} {
    padding: 0 6px;
  }

  @media ${device.tablet} {
    max-width: 1200px;
    padding: 0 12px;
  }

  @media ${device.laptopL} {
    max-width: 1440px;
    padding: 0 12px;
  }

  @media ${device.desktop} {
    padding: 0 24px;
  }

  &--small {
    max-width: 760px;
    margin: 0 auto;
  }
}

.leaflet-container {
  padding-bottom: 30rem;
}

.slick-slide img {
  max-height: 480px;
  width: 100%;

  @media ${device.tablet} {
    width: auto;
  }
}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    { element }
  </ThemeProvider>
)